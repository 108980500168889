const tutors = [
    {
        id: 1,
        fName: "Obed",
        lName: "Nwachukwu",
        email: "neutrixclass@gmail.com",
        linkedIn: "",
        imgURL: `${process.env.PUBLIC_URL}/img/obed.jpeg`,
        phone: "xxx-1234-xxx",
    }
];

export default tutors;