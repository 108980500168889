import React from 'react';
// import { useNavigate } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
// import PDFViewer from '../components/PdfViewer';
import '../styles/custom-style.css'

const Mathematics = ()  => {
    // const pdfUrl = 'client/src/res/obed-nhs-sql-cv.pdf'

    // return (
    //     <div className='maths-layout'>
    //         <Sidebar/>
    //         <div className='maths-content'>
    //             <h1>Mathematics Resources</h1>
    //             {/* <PDFViewer pdfUrl={pdfUrl} /> */}
    //         </div>
    //         <Sidebar/>
    //     </div>
    // )
}

export default Mathematics;